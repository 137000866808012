import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import { FormEntity, RecaptchaEntity } from '@core/type/api';

import { SubscribeAPI } from '../api/subscribe';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R | void> => {
  return logService('SubscribeService', methodName, dto, apiCall, withContent);
};

const subscribeEmail = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: { email: string } & RecaptchaEntity,
): Promise<FormEntity | void> => {
  return log('subscribeEmail', { axiosInstance, language, ...data }, SubscribeAPI.subscribeEmail);
};

export class SubscribeService {
  public static readonly subscribeEmail = subscribeEmail;
}
