import { EGTMCaptureLocation, EGTMEventName } from '@core/type';

import { useContextGTM } from '../context-gtm';
import { getEventOriginUrl, pushGTMEvent } from '../helpers';

export const useEventEmailCaptured = () => {
  const { enable, baseDatalayerProps } = useContextGTM();

  const pushEventEmailCaptured = (capturedLocation: EGTMCaptureLocation) => {
    if (enable) {
      pushGTMEvent({
        ...baseDatalayerProps,
        eventOrigin: getEventOriginUrl(baseDatalayerProps),
        event: EGTMEventName.CAPTURE_EMAIL,
        capturedLocation,
      });
    }
  };

  return { pushEventEmailCaptured };
};
