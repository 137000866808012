import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';
import { FC } from 'react';

import { Translate } from '@core/constant';

import { InputSubscribeControl } from './InputSubscribeControl';
import { InputEnterEmailProps } from './interface-form-input';

const InputEnterEmailContainer: FC<InputEnterEmailProps> = ({
  i18n: { t },
  ...inputProps
}: InputEnterEmailProps): JSX.Element => {
  const { t: tAria } = useTranslation(Translate.common.ARIA);

  return (
    <InputSubscribeControl
      name="email"
      aria-label={tAria('emailInput')}
      placeholder={t('email.placeholder')}
      type="email"
      {...inputProps}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const InputEnterEmail = withTranslation(InputEnterEmailContainer, Translate.common.FORM);
