import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import { FormEntity, RecaptchaEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const subscribeEmail = async (
  axiosInstance: AxiosInstance,
  {
    language,
    email,
    token,
  }: LanguageProps &
    RecaptchaEntity & {
      email: string;
    },
): Promise<AxiosResponse<FormEntity>> =>
  axiosInstance.post(
    addParameters(Api.subscribe.SUBSCRIBE_EMAIL, {
      email,
      token,
    }),
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class SubscribeAPI {
  public static readonly subscribeEmail = subscribeEmail;
}
